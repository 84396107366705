import { getLauncher } from '$stores/auth';
import type { PageLoad } from './$types';

export const load: PageLoad = async ({ parent }) => {
    await parent();

    // Product 및 권한이 추가되기 전까지 임시로 사용
    const data = await getLauncher();
    const { workspaceId, tenantId } = data?.[0] || {};

    const workspaceName = `${tenantId}-${workspaceId}`;

    return {
        workspaceName,
    };
};
