<script lang="ts">
    import { _ as t } from 'svelte-i18n';
    import { OverlayScrollbars } from 'overlayscrollbars';
    import { browser, dev } from '$app/environment';
    import { onMount } from 'svelte';

    import { transformUrl } from '$lib/utils';
    import { goto, afterNavigate } from '$app/navigation';
    import type { PageData } from './$types';

    let contentsRef;
    onMount(() => {
        OverlayScrollbars(contentsRef, {
            scrollbars: {
                autoHide: 'scroll',
            },
        });
    });

    export let data: PageData;

    afterNavigate(() => {
        if (!data.session) {
            if (browser) {
                goto(transformUrl('/sign-in'), {
                    replaceState: true,
                    state: {
                        needAlertLogin: true,
                    },
                });
            }
        } else {
            goto(transformUrl(`/${data.workspaceName}/main/`), {
                replaceState: true,
            });
        }
    });
</script>

<svelte:head>
    <title>BHSN.AI Studio</title>
</svelte:head>

<div class="grid h-auto min-h-full {dev ? 'grid-cols-[auto_1fr]' : ''} ">
    <div bind:this={contentsRef} class="h-full min-w-[50rem] flex-1">
        <div class="p-4">
            <h1 class="text-2xl font-bold">AI Studio</h1>
            <p class="text-sm text-gray-500">AI Studio is a platform for creating, training, and deploying machine learning models.</p>
        </div>
    </div>
</div>
